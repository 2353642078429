// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/calculator/LoanCalculator.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/calculator/LoanCalculator.tsx");
  import.meta.hot.lastModified = "1734532512929.2114";
}
// REMIX HMR END

import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Slider } from "../ui/slider";
import { Input } from "../ui/input";
function LoanCalculator() {
  _s();
  const [data, setData] = useState({
    loanAmount: 650000,
    repaymentPeriod: 11,
    interestRate: 5.9
  });
  const [resultCalc, setResultCalc] = useState({
    totalInterestAndFees: 0,
    totalAmount: 0
  });
  const onChangeHandler = name => item => {
    if (Array.isArray(item)) {
      let val = item[0];
      return setData(prev => ({
        ...prev,
        [name]: val
      }));
    }
    let qty = isNaN(parseInt(item.target.value)) ? 0 : parseInt(item.target.value);
    setData(prev => ({
      ...prev,
      [name]: qty
    }));
  };
  const calculateTotalInterestAndFees = () => {
    const monthlyInterestRate = data.interestRate / 100 / 12;
    const totalInterest = data.loanAmount * monthlyInterestRate * data.repaymentPeriod;
    setResultCalc({
      totalInterestAndFees: totalInterest,
      totalAmount: data.loanAmount + totalInterest
    });
  };
  const formatNumber = (number, decimals = 2) => {
    return new Intl.NumberFormat("no-NO", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(number);
  };
  useEffect(() => {
    calculateTotalInterestAndFees();
  }, [data]);
  return <div className="container max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold mb-8 text-center">Låneregner</h1>
      <div className="flex gap-3">
        <div className="sm:w-1/2">
          <div className="flex flex-col">
            <Label>La nestørrelse</Label>
            <Input value={data.loanAmount + " kr"} onChange={onChangeHandler("loanAmount")} className="-mb-1 h-16 text-2xl text-center" name="loanAmount" />
            <Slider min={0} max={2000000} step={50000} name="loanAmount" value={[data.loanAmount]} onValueChange={onChangeHandler("loanAmount")} className="pb-5" />
          </div>
          <div className="flex flex-col">
            <Label>Avgjørelsesperiode i måneder</Label>
            <Input value={data.repaymentPeriod + " måneder"} onChange={onChangeHandler("repaymentPeriod")} className="-mb-1 h-16 text-2xl text-center" name="repaymentPeriod" />
            <Slider min={0} max={36} step={1} name="repaymentPeriod" value={[data.repaymentPeriod]} onValueChange={onChangeHandler("repaymentPeriod")} className="pb-5" />
          </div>
          <div className="flex flex-col">
            <Label>Nominell rente %</Label>
            <Input value={data.interestRate + "%"} onChange={onChangeHandler("interestRate")} className="-mb-1 h-16 text-2xl text-center" name="interestRate" />
            <Slider min={0} max={100} step={0.1} name="interestRate" value={[data.interestRate]} onValueChange={onChangeHandler("interestRate")} className="pb-5" />
          </div>
        </div>
        <div className="sm:w-1/2 flex flex-col gap-2 p-14">
          <div className="flex flex-col *:text-center">
            <Label className="text-xl font-normal">Renter og gebyr</Label>
            <p className="text-4xl font-semibold">
              {formatNumber(resultCalc.totalInterestAndFees)} kr
            </p>
          </div>
          <div className="flex flex-col *:text-center pb-4">
            <Label className="text-xl font-normal">Totalt</Label>
            <p className="text-4xl font-semibold">
              {formatNumber(resultCalc.totalAmount)} kr
            </p>
          </div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Søk bedriftslån nå
          </button>
        </div>
      </div>
    </div>;
}
_s(LoanCalculator, "rn6l2STjhXO7I8VjINkOC4OhGmU=");
_c = LoanCalculator;
export default LoanCalculator;
var _c;
$RefreshReg$(_c, "LoanCalculator");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;