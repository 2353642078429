// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/search.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/search.tsx");
  import.meta.hot.lastModified = "1734532513034.2156";
}
// REMIX HMR END

import { Input } from "@/components/ui/input";
import { AppContext } from "@/root";
import { useFetcher } from "@remix-run/react";
import React, { useContext, useEffect } from "react";
import z from "zod";
export async function loader({
  request
}) {
  const q = new URL(request.url).searchParams.get("q");
  const regex = /^[\u0020-\u007F]+$/;
  if (!q || !regex.test(q)) return [];
  const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?navn=${q}`);
  const data = await response.json();
  const companies = data?._embedded?.enheter;
  return {
    companies
  };
}
export const searchFormSchema = z.object({
  email: z.string().email(),
  phone: z.string().min(8)
});
export default function Search() {
  _s();
  const fetcher = useFetcher();
  const [isSelectItem, setIsSelectItem] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const {
    setEmail,
    setPhone,
    setCompanyId,
    setCompany,
    company,
    errors,
    email,
    phone
  } = useContext(AppContext);
  useEffect(() => {
    if (company) {
      setInputValue(company.navn);
    }
  }, [company]);
  const handleItemClick = item => {
    setInputValue(item.navn);
    setOpen(false);
    setIsSelectItem(true);
    setCompanyId(item.organisasjonsnummer);
    setCompany(item);
  };
  return <div className="relative">
      <fetcher.Form method="get" action="/search">
        <Input autoComplete="off" type="text" name="q" value={inputValue} className="h-[60px] border-[#82C9E4]" placeholder="S k etter din bedrift" onChange={event => {
        fetcher.submit(event.currentTarget.form);
        setInputValue(event.target.value);
      }} onFocus={() => setOpen(true)} />
      </fetcher.Form>

      {fetcher.data && fetcher.data.companies ? open && <ul className="py-3 absolute w-full h-64 overflow-y-scroll bg-white">
              {fetcher.data.companies.map((item, index) => <li key={index} className="py-1 px-5 cursor-pointer hover:bg-gray-200" onClick={() => handleItemClick(item)}>
                  {`${item.navn} / ${item.organisasjonsnummer}`}
                </li>)}
            </ul> : null}
    </div>;
}
_s(Search, "iL92fNDbpbVUphKKE8emEpZPrY8=", false, function () {
  return [useFetcher];
});
_c = Search;
var _c;
$RefreshReg$(_c, "Search");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;