// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/mockData.ts"
);
import.meta.hot.lastModified = "1734532513033.2156";
}
// REMIX HMR END

export const mockData = {
  navigation: [
    {
      title: "Calculator",
      id: "calculator",
      slug: "calculator",
    },
    {
      title: "Bedriftslån",
      id: "bedriftslan",
      slug: "bedriftslan",
    },
    {
      title: "Kassekreditt",
      id: "kassekreditt",
      slug: "kassekreditt",
    },
    {
      title: "Fakturasalg",
      id: "fakturasalg",
      slug: "fakturasalg",
    },
    {
      title: "Høyrente konto",
      id: "hoyrente-konto",
      slug: "hoyrente-konto",
    },
  ],
  latestCalculation: [
    {
      bank: "Bank of America",
      save: 1000,
      rate: 3.5,
      offers: 3,
      applied: 750,
      name: "Mark",
      age: 35,
    },
    {
      bank: "JPMorgan Chase",
      save: 1200,
      rate: 3.2,
      offers: 10,
      applied: 250,
      name: "John",
      age: 40,
    },
    {
      bank: "Wells Fargo",
      save: 1100,
      rate: 3.4,
      offers: 7,
      applied: 350,
      name: "Jane",
      age: 31,
    },
  ],
  categories: [
    {
      title: "Mortgage",
      id: "mortgage",
      slug: "mortgage",
      description: "Mortgage description",
      subCategories: [
        {
          title: "Fixed Rate",
          id: "fixed-rate",
          slug: "fixed-rate",
          description: "Fixed Rate description",
        },
        {
          title: "Adjustable Rate",
          id: "adjustable-rate",
          slug: "adjustable-rate",
          description: "Adjustable Rate description",
        },
        {
          title: "Interest Only",
          id: "interest-only",
          slug: "interest-only",
          description: "Interest Only description",
        },
      ],
    },
    {
      title: "Refinancing",
      id: "refinancing",
      slug: "refinancing",
      description: "Refinancing description",
      subCategories: [
        {
          title: "Fixed Rate",
          id: "fixed-rate",
          slug: "fixed-rate",
          description: "Fixed Rate description",
        },
        {
          title: "Adjustable Rate",
          id: "adjustable-rate",
          slug: "adjustable-rate",
          description: "Adjustable Rate description",
        },
        {
          title: "Interest Only",
          id: "interest-only",
          slug: "interest-only",
          description: "Interest Only description",
        },
      ],
    },
    {
      title: "Consumer Loans",
      id: "consumer-loans",
      slug: "consumer-loans",
      description: "Consumer Loans description",
      subCategories: [
        {
          title: "Personal Loans",
          id: "personal-loans",
          slug: "personal-loans",
          description: "Personal Loans description",
        },
        {
          title: "Auto Loans",
          id: "auto-loans",
          slug: "auto-loans",
          description: "Auto Loans description",
        },
        {
          title: "Student Loans",
          id: "student-loans",
          slug: "student-loans",
          description: "Student Loans description",
        },
      ],
    },
    {
      title: "Credit Card",
      id: "credit-card",
      slug: "credit-card",
      description: "Credit Card description",
      subCategories: [
        {
          title: "Cash Back",
          id: "cash-back",
          slug: "cash-back",
          description: "Cash Back description",
        },
        {
          title: "Travel Rewards",
          id: "travel-rewards",
          slug: "travel-rewards",
          description: "Travel Rewards description",
        },
        {
          title: "Balance Transfer",
          id: "balance-transfer",
          slug: "balance-transfer",
          description: "Balance Transfer description",
        },
      ],
    },
  ],
  reviews: [
    {
      text: "“They provide fantastic customer service and are easy to get in touch with. If you want to avoid a lengthy standard loan process...”",
      name: "John Doe",
      position: "CEO, Company",
      id: "john-doe",
    },
    {
      text: "“They provide fantastic customer service and are easy to get in touch with. If you want to avoid a lengthy standard loan process...”",
      name: "Jane Doe",
      position: "Business Owner",
      id: "jane-doe",
    },
    {
      text: "“They provide fantastic customer service and are easy to get in touch with. If you want to avoid a lengthy standard loan process...”",
      name: "Jack Doe",
      position: "AI Analyst",
      id: "jack-doe",
    },
  ],
};
